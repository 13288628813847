<template>
  <div>
    <el-table
      :data="list"
      row-key="id"
      default-expand-all
      :tree-props="{children: 'children'}"
      :header-cell-style="{backgroundColor: '#f6f8fa'}"
      border>
      <el-table-column prop="title" label="名称"></el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-link
            :underline="false"
            class="list-button"
            style="font-size: 12px;"
            @click="handleCommonBtnCLick('edit', scope.row)"
            type="primary">
            编辑
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <!-- <lz-dialog
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      :title="title"
      ref="lzDialog">
      <lz-form :form="form" :col="3" ref="lzForm"></lz-form>
    </lz-dialog> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      form: [
        { fieldName: 'content', type: 'editor', name: '详细信息', value: '', required: false, width: '100%' },
        { fieldName: 'menu_id', type: 'text', name: 'menu_id', value: '', hide: true },
      ],
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
      title: '',
    };
  },
  mounted() {
    this.$baseHttp.get('/auth-rule/menu').then((response) => {
      this.setChildren(response.data);
      this.list = response.data;
    });
  },
  methods: {
    setChildren(response) {
      response.forEach((element) => {
        if (element.children && element.children.length > 0) {
          this.setChildren(element.children);
          return;
        }
        this.$set(element, 'children', []);
      });
    },
    async handleCommonBtnCLick(type, element) {
      switch (type) {
        case 'edit':
          this.$router.push({
            name: `${this.$route.name}-view`,
            query: {
              id: element ? element.id : '',
            },
          });
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
